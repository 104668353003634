import {
  TOMTOM_GEOCODE_API_URL,
  TOMTOM_REVERSE_GEOCODE_API_URL,
  TOMTOM_SEARCH_API_URL,
} from "common/utils/api";

export async function getCoordinates(address: string) {
  const response = await fetch(
    `${TOMTOM_GEOCODE_API_URL}/${address}.json?key=${process.env.GATSBY_TOMTOM_API_KEY}`
  );

  const data = await response.json();

  const coordinates = data.results[0].position;

  return coordinates;
}

export async function getAddressForCoordinates(
  coordinates: GeolocationCoordinates
) {
  try {
    const response = await fetch(
      `${TOMTOM_REVERSE_GEOCODE_API_URL}/${coordinates.latitude},${coordinates.longitude}.json?key=${process.env.GATSBY_TOMTOM_API_KEY}`
    );

    const data = await response.json();

    const address = data.addresses[0].address;

    return address.freeformAddress;
  } catch (error) {
    console.error(error);
  }
}

export async function getPlaces(query: string) {
  let invalidQuery = false;

  // Replace all special characters except letters (including polish language specific ones), numbers and spaces
  const sanitizedQuery = query.replace(/[^\w ąćęłńóśźż]/g, "");

  if (query !== sanitizedQuery) invalidQuery = true;

  if (!sanitizedQuery)
    return {
      data: [] as Array<object>,
      invalidQuery: invalidQuery,
    };

  try {
    const response = await fetch(
      `${TOMTOM_SEARCH_API_URL}/${sanitizedQuery}.json?key=${process.env.GATSBY_TOMTOM_API_KEY}&countrySet=PL&language=pl-PL&typeahead=true`
    );

    const data = await response.json();

    return {
      data: data.results,
      invalidQuery: invalidQuery,
    };
  } catch (error) {
    console.error(error);
    return {
      data: [] as Array<object>,
      invalidQuery: invalidQuery,
    };
  }
}
