const TOMTOM_SEARCH_BASE_API_URL = "https://api.tomtom.com/search/2";
const TOMTOM_ROUTING_BASE_API_URL = "https://api.tomtom.com/routing/1";

const TOMTOM_SEARCH_API_URL = `${TOMTOM_SEARCH_BASE_API_URL}/search`;
const TOMTOM_AUTOCOMPLETE_API_URL = `${TOMTOM_SEARCH_BASE_API_URL}/autocomplete`;
const TOMTOM_GEOCODE_API_URL = `${TOMTOM_SEARCH_BASE_API_URL}/geocode`;
const TOMTOM_REVERSE_GEOCODE_API_URL = `${TOMTOM_SEARCH_BASE_API_URL}/reverseGeocode`;
const TOMTOM_CALCULATE_ROUTE_API_URL = `${TOMTOM_ROUTING_BASE_API_URL}/calculateRoute`;

const CALCULATE_DELIVERY_API_URL = "/api/calculateDelivery";
const CREATE_CHECKOUT_API_URL = "/api/createCheckout";

export {
  CALCULATE_DELIVERY_API_URL,
  CREATE_CHECKOUT_API_URL,
  TOMTOM_AUTOCOMPLETE_API_URL,
  TOMTOM_CALCULATE_ROUTE_API_URL,
  TOMTOM_GEOCODE_API_URL,
  TOMTOM_REVERSE_GEOCODE_API_URL,
  TOMTOM_SEARCH_API_URL,
};
