import {
  type AnyAction,
  type PayloadAction,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { getCoordinates } from "common/services/Location";
import type { Product } from "common/types";
import { CALCULATE_DELIVERY_API_URL } from "common/utils/api";
import getShopCoordinates from "common/utils/getShopCoordinates";

import type { RootState } from "../store";
import initialState from "./initialState";
import type { DeliveryDestination } from "./types";

interface CalculateDeliveryProps
  extends Omit<DeliveryDestination, "coordinates"> {
  originAddress: Product["location"];
  rejectWithValue: any;
}

export const calculateDelivery = createAsyncThunk(
  "order/calculateDelivery",
  async ({
    address,
    floor,
    floorCount,
    elevator,
    originAddress,
    rejectWithValue,
  }: CalculateDeliveryProps) => {
    try {
      const originCoordinates = getShopCoordinates(originAddress);
      const destinationCoordinates = await getCoordinates(address);

      const coordinates = {
        origin: originCoordinates,
        destination: destinationCoordinates,
      };

      const response = await fetch(CALCULATE_DELIVERY_API_URL, {
        method: "POST",
        body: JSON.stringify({ coordinates, floor, floorCount, elevator }),
      });

      const data = await response.json();

      return data;
    } catch (error) {
      console.error(error);
      rejectWithValue(error);
    }
  }
);

const reducers = {
  resetOrder: () => {
    return initialState;
  },
  updateProduct: (state: any, action: PayloadAction<Product>) => {
    state.product = action.payload;
  },
  updateDeliveryDestination: (
    state: any,
    action: PayloadAction<Omit<DeliveryDestination, "coordinates">>
  ) => {
    state.delivery.destination = action.payload;
  },
};

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers,
  extraReducers(builder) {
    builder.addCase(calculateDelivery.pending, (state) => {
      state.submitable = false;
    });

    builder.addCase(calculateDelivery.fulfilled, (state, action: AnyAction) => {
      const { coordinates, cost, distance } = action.payload;

      state.delivery.cost = cost;

      state.delivery.origin.coordinates = coordinates.origin;
      state.delivery.destination.coordinates = coordinates.destination;

      state.delivery.distance = distance;

      state.submitable = true;
    });
  },
});

export const { resetOrder, updateProduct, updateDeliveryDestination } =
  orderSlice.actions;

export const selectOrder = () => (state: RootState) => state.order;

export const selectProduct = () => (state: RootState) => state.order.product;

export const selectDelivery = () => (state: RootState) => state.order.delivery;

export const selectDeliveryDestination = () => (state: RootState) =>
  state.order.delivery.destination;

export default orderSlice.reducer;
