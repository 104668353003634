exports.components = {
  "component---src-common-templates-order-details-index-tsx": () => import("./../../../src/common/templates/OrderDetails/index.tsx" /* webpackChunkName: "component---src-common-templates-order-details-index-tsx" */),
  "component---src-common-templates-product-index-tsx": () => import("./../../../src/common/templates/Product/index.tsx" /* webpackChunkName: "component---src-common-templates-product-index-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-potwierdzenie-platnosci-tsx": () => import("./../../../src/pages/potwierdzenie-platnosci.tsx" /* webpackChunkName: "component---src-pages-potwierdzenie-platnosci-tsx" */),
  "component---src-pages-renowacje-tsx": () => import("./../../../src/pages/renowacje.tsx" /* webpackChunkName: "component---src-pages-renowacje-tsx" */),
  "component---src-pages-sklep-tsx": () => import("./../../../src/pages/sklep.tsx" /* webpackChunkName: "component---src-pages-sklep-tsx" */),
  "component---src-pages-strojenie-i-naprawa-tsx": () => import("./../../../src/pages/strojenie-i-naprawa.tsx" /* webpackChunkName: "component---src-pages-strojenie-i-naprawa-tsx" */)
}

