import { CookieConsentProvider } from "@use-cookie-consent/react";
import OrderContextProvider from "common/context/OrderContext";
import { store } from "common/redux/store";
import React from "react";
import { Provider } from "react-redux";

// eslint-disable-next-line react/display-name,react/prop-types
const customWrapRootElement = ({ element }) => {
  return (
    <Provider store={store}>
      <OrderContextProvider>
        <CookieConsentProvider
          useCookieConsentHooksOptions={{
            consentCookieAttributes: { expires: 180 },
          }}
        >
          {element}
        </CookieConsentProvider>
      </OrderContextProvider>
    </Provider>
  );
};

export default customWrapRootElement;
