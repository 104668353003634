import type { TransportFormFields } from "common/templates/OrderDetails/TransportFormProvider";
import React, { useContext, useState } from "react";

export interface Order {
  product: any;
  delivery: boolean;
  deliveryDetails: {
    cost: {
      total: number;
      bringing: number;
    };
    startCoordinates: { lat: number; lon: number } | null;
    endCoordinates: { lat: number; lon: number } | null;
    distance: number;
  } & TransportFormFields;
}

interface OrderContextType {
  order: Order;
  updateOrder: (order: Partial<Order>) => void;
  resetOrder: () => void;
  resetDelivery: () => void;
}

const initialOrder: Order = {
  product: null,
  delivery: true,
  deliveryDetails: {
    cost: {
      total: 0,
      bringing: 0,
    },
    startCoordinates: null,
    endCoordinates: null,
    address: "",
    municipality: "",
    postalCode: "",
    distance: 0,
    elevator: false,
    floor: "first",
    floorCount: 0,
  },
};

export const OrderContext = React.createContext<OrderContextType | null>(null);

const OrderContextProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [order, setOrder] = useState<OrderContextType["order"]>(initialOrder);

  function updateOrder(newOrder: Partial<OrderContextType["updateOrder"]>) {
    setOrder((prevOrder: Order) => ({ ...prevOrder, ...newOrder }));
  }

  function resetOrder() {
    setOrder(initialOrder);
  }

  function resetDelivery() {
    setOrder((prevOrder: Order) => ({
      ...prevOrder,
      delivery: false,
      deliveryDetails: { ...initialOrder.deliveryDetails },
    }));
  }

  return (
    <OrderContext.Provider
      value={{ order, updateOrder, resetOrder, resetDelivery }}
    >
      {children}
    </OrderContext.Provider>
  );
};

export default OrderContextProvider;

export const useOrderContext = () =>
  useContext(OrderContext) as OrderContextType;
