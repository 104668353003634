import type { OrderState } from "./types";

const initialState: OrderState = {
  product: null,
  delivery: {
    cost: {
      perKm: 0,
      total: 0,
      trip: 0,
      bringing: 0,
    },
    origin: {
      coordinates: null,
      address: "",
    },
    destination: {
      coordinates: null,
      address: "",
      floor: "first",
      floorCount: 0,
      elevator: false,
    },
    distance: 0,
  },
  submitable: false,
};

export default initialState;
