const getShopCoordinates = (shopLocation: string) => {
  switch (shopLocation) {
    case "ul. Wałowa 4, Tarnów":
      return { lat: 50.01269, lon: 20.98567 };
    case "ul. Smoluchowskiego 7, Kraków":
      return { lat: 50.072, lon: 19.90906 };
    default:
      return null;
  }
};

export default getShopCoordinates;
